
import { defineComponent, onMounted } from "vue";
import ListTable from "@/components/widgets/tables/ListTable.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "lists",
  components: {
    ListTable,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Lists");
    });
  },
});
